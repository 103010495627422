
.form-page-submited {
  margin: auto;
  background: #fff;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  .image-wrap {
    img {
      width: 230px;
    }
  }
  .msg {
    font-size: 24px;
    font-weight: 600;
    color: #121933;
  }
  .action {
    margin-top: 40px;
  }
}
.formview-subapp {
position: relative;
// top: 0;
// right: 0;
// left: 0;
// bottom: 0;
overflow-y: scroll;
height: 100%;
display: flex;
flex-direction: column;
justify-content: space-between;
background-color: #f1f2f5;
}
.share-form {
  background-color: #fff;
  overflow-y: auto;
  padding-bottom: 30px;
}
.form-view-footer {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  image {
    height: 24px;
    width: 24px;
  }
  span {
    color: #707481;
    font-size: 12px;
  }
}

@h3-blue: #107fff;@h3-red: #ff4384;@h3-orange: #FF870F;@h3-green: #00d495;@h3-purple: #6840ff;@h3-yellow: #ffca00;