













#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

@h3-blue: #107fff;@h3-red: #ff4384;@h3-orange: #FF870F;@h3-green: #00d495;@h3-purple: #6840ff;@h3-yellow: #ffca00;