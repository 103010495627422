









































































































































































































































































































































































































































































.formview-subapp {
  position: relative;
  // overflow-y: scroll;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f1f2f5;
  .loading-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .form-view-wrap {
    padding: 12px 0;
    margin: auto;
    position: relative;
    flex-grow: 1;
  }
  .form-view {
    position: relative;
    padding: 32px;
    width: 863px;
    margin: auto;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    .form-bg {
      transition: opacity 0.5s linear;
      border-radius: 4px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
      background-size: 100%;
      background-repeat: no-repeat;
    }
    &.bi .form-bg {
      // background-image: url('https://static.iyouge.com/common-resource/illustration/formview/bi.png');
      background-color: #F8F7F6;
    }
    &.diannao .form-bg {
      // background-image: url('https://static.iyouge.com/common-resource/illustration/formview/diannao.png');
      background-color: #EEEFF3;
    }
    &.shanshui .form-bg {
      // background-image: url('https://static.iyouge.com/common-resource/illustration/formview/shanshui.png');
      background-color: #000;
    }
    &.xingkong .form-bg {
      // background-image: url('https://static.iyouge.com/common-resource/illustration/formview/xingkong.png');
      background-color: #000;
    }
    &.yu .form-bg {
      // background-image: url('https://static.iyouge.com/common-resource/illustration/formview/yu.png');
      background-color: #2F3557;
    }
  }
  .form-view-bar {
    position: absolute;
    top: 32px;
    right: 32px;
    left: 32px;
    min-height: 32px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    .set-cover-btn {
      width: 106px;
      height: 36px;
      background: #FFF;
      border: 1px solid #DEE0EA;
      color: #121933;
      border-radius: 2px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
    .form-cover-thumbnail {
      &.active-cover {
        outline: solid #fff 2px;
      }
      display: inline-flex;
      height: 32px;
      width: 67px;
      border-radius: 2px;
      margin-left: 8px;
      background-size: cover;
      // &.bi {
      //   background-image: url('https://static.iyouge.com/common-resource/illustration/formview/thumbnail/bi.png');
      // }
      // &.diannao {
      //   background-image: url('https://static.iyouge.com/common-resource/illustration/formview/thumbnail/diannao.png');
      // }
      // &.shanshui {
      //   background-image: url('https://static.iyouge.com/common-resource/illustration/formview/thumbnail/shanshui.png');
      // }
      // &.xingkong {
      //   background-image: url('https://static.iyouge.com/common-resource/illustration/formview/thumbnail/xingkong.png');
      // }
      // &.yu {
      //   background-image: url('https://static.iyouge.com/common-resource/illustration/formview/thumbnail/yu.png');
      // }
      &.selected {
        outline: 1px solid #fff;
      }
    }
  }
  .form-view-title {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    padding-bottom: 8px;
    h2 {
      font-size: 16px;
      font-weight: 600;
      color: #FFF;
      line-height: 24px;
      margin-bottom: 0px;
      transition: color 0.5s;
      div, span, ::-webkit-input-placeholder {
        transition: color 0.5s;
      }
      ::-webkit-input-placeholder {
        color: #fff;
      }
      &.deep-color {
        color: #121933;
        input {
          color: #121933 !important;
        }
        ::-webkit-input-placeholder {
          color: #121933 !important;
        }
        .hit-icon-edit-stroke {
          color: #666;
        }
      }
      > div {
        cursor: pointer;
      }
      .edit-title.ant-input {
        background: transparent;
        border: none;
        box-shadow: none;
        padding: 0;
        height: 24px;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .descript-readonly {
      cursor: default;
    }
    textarea {
      font-size: 12px;
      font-weight: 400;
      color: #FFF;
      line-height: 20px;
      margin: auto;
      padding: 0;
      background: transparent !important;
      box-shadow: none !important;
      border: none;
      resize: none;
      &.form-descript {
        background: transparent !important;
        box-shadow: none !important;
        border: none;
        resize: none;
      }
      &.deep-color {
        color: #707481;
        ::-webkit-input-placeholder {
          color: #707481;
        }
      }
    }
  }
  .form-view-body {
    margin-top: 160px;
    border-radius: 4px;
    background-color: #fff;
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  .form-view-content {
    padding: 32px;
    min-height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .h3yun-form-control-adapter {
      flex-grow: 1;
    }
    // text-align: center;
  }

  // .setting-form-view .h3yun-control-main {
  //   pointer-events: none;
  //   overflow: hidden;
  // }
  .h3yun-control-title h4 {
    margin-bottom: 0;
  }
}
.form-view-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f1f2f5;
  .loading-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .form-view-container {
    width: 100%;
    padding: 12px 0;
    margin: auto;
    position: relative;
    flex-grow: 1;
    overflow-y: scroll;
    // height: 100%;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    // background-color: #f1f2f5;
  }
  .form-view {
    position: relative;
    padding: 32px;
    width: 863px;
    margin: auto;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    .form-bg {
      transition: opacity 0.5s linear;
      border-radius: 4px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
      background-size: 100%;
      background-repeat: no-repeat;
    }
    &.bi .form-bg {
      // background-image: url('https://static.iyouge.com/common-resource/illustration/formview/bi.png');
      background-color: #f8f7f6;
    }
    &.diannao .form-bg {
      // background-image: url('https://static.iyouge.com/common-resource/illustration/formview/diannao.png');
      background-color: #eeeff3;
    }
    &.shanshui .form-bg {
      // background-image: url('https://static.iyouge.com/common-resource/illustration/formview/shanshui.png');
      background-color: #000;
    }
    &.xingkong .form-bg {
      // background-image: url('https://static.iyouge.com/common-resource/illustration/formview/xingkong.png');
      background-color: #000;
    }
    &.yu .form-bg {
      // background-image: url('https://static.iyouge.com/common-resource/illustration/formview/yu.png');
      background-color: #2f3557;
    }
  }
  .form-view-bar {
    position: absolute;
    top: 32px;
    right: 32px;
    left: 32px;
    min-height: 32px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    .set-cover-btn {
      width: 106px;
      height: 36px;
      background: #fff;
      border: 1px solid #dee0ea;
      color: #121933;
      border-radius: 2px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
    .form-cover-thumbnail {
      &.active-cover {
        outline: solid #fff 2px;
      }
      display: inline-flex;
      height: 32px;
      width: 67px;
      border-radius: 2px;
      margin-left: 8px;
      background-size: cover;
      // &.bi {
      //   background-image: url('https://static.iyouge.com/common-resource/illustration/formview/thumbnail/bi.png');
      // }
      // &.diannao {
      //   background-image: url('https://static.iyouge.com/common-resource/illustration/formview/thumbnail/diannao.png');
      // }
      // &.shanshui {
      //   background-image: url('https://static.iyouge.com/common-resource/illustration/formview/thumbnail/shanshui.png');
      // }
      // &.xingkong {
      //   background-image: url('https://static.iyouge.com/common-resource/illustration/formview/thumbnail/xingkong.png');
      // }
      // &.yu {
      //   background-image: url('https://static.iyouge.com/common-resource/illustration/formview/thumbnail/yu.png');
      // }
      &.selected {
        outline: 1px solid #fff;
      }
    }
  }
  .form-view-title {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    padding-bottom: 8px;
    h2 {
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      line-height: 24px;
      margin-bottom: 0px;
      transition: color 0.5s;
      div,
      span,
      ::-webkit-input-placeholder {
        transition: color 0.5s;
      }
      ::-webkit-input-placeholder {
        color: #fff;
      }
      &.deep-color {
        color: #121933;
        input {
          color: #121933 !important;
        }
        ::-webkit-input-placeholder {
          color: #121933 !important;
        }
        .hit-icon-edit-stroke {
          color: #666;
        }
      }
      > div {
        cursor: pointer;
      }
      .edit-title.ant-input {
        background: transparent;
        border: none;
        box-shadow: none;
        padding: 0;
        height: 24px;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .descript-readonly {
      cursor: default;
    }
    textarea {
      font-size: 12px;
      font-weight: 400;
      color: #fff;
      line-height: 20px;
      margin: auto;
      padding: 0;
      background: transparent !important;
      box-shadow: none !important;
      border: none;
      resize: none;
      &.form-descript {
        background: transparent !important;
        box-shadow: none !important;
        border: none;
        resize: none;
      }
      &.deep-color {
        color: #707481;
        ::-webkit-input-placeholder {
          color: #707481;
        }
      }
    }
  }
  .form-view-body {
    margin-top: 160px;
    border-radius: 4px;
    background-color: #fff;
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  .form-view-content {
    padding: 32px;
    min-height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .h3yun-form-control-adapter {
      flex-grow: 1;
    }
    // text-align: center;
  }

  // .setting-form-view .h3yun-control-main {
  //   pointer-events: none;
  //   overflow: hidden;
  // }
  .h3yun-control-title h4 {
    margin-bottom: 0;
  }
}
.loading-fixed,
#subapp-formview {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
.qr-code-wrap {
  position: absolute;
  top: 200px;
  left: 100%;
  height: 38px;
  width: 38px;
  background-color: #fff;
  font-size: 28px;
  .qrcode-icon:hover {
    cursor: pointer;
  }
}
.form-view-footer {
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  image {
    height: 24px;
    width: 24px;
  }
  span {
    color: #707481;
    font-size: 12px;
  }
}
.share-form {
  background-color: #fff;
  // overflow-y: auto;
  padding-bottom: 30px;
}

@h3-blue: #107fff;@h3-red: #ff4384;@h3-orange: #FF870F;@h3-green: #00d495;@h3-purple: #6840ff;@h3-yellow: #ffca00;