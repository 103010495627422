html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", SimSun, sans-serif;
  width: 100%;
  height: 100%;
}
* {
  margin: 0;
  padding: 0;
}
ul,
li,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}
i {
  font-style: initial;
}
a:hover,
a:active,
a:visited {
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
button {
  border: none;
  background: none;
}
button:focus {
  outline: none;
}

@supports (bottom: env(safe-area-inset-bottom)) {
  body::after {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    content: "";
    display: block;
    height: env(safe-area-inset-bottom);
    background-color: #fff;
    transform: translateY(100%);
  }
}

@h3-blue: #107fff;@h3-red: #ff4384;@h3-orange: #FF870F;@h3-green: #00d495;@h3-purple: #6840ff;@h3-yellow: #ffca00;